import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState, ContentState, convertFromRaw } from 'draft-js';
import styles from './rich-text-editor.module.scss';
import {
  dot_list_icon,
  ic_text_align_center,
  ic_text_align_justify,
  ic_text_align_left,
  ic_text_align_right,
  ic_text_bold,
  ic_text_italic,
  ic_text_strikethrough,
  ic_text_underline,
  number_list_icon,
  redo_icon,
  text_editor_active_icon,
  text_editor_icon,
  undo_icon,
} from '../../assets/img';

const toolBarOptions = {
  options: ['inline', 'textAlign', 'list', 'history'],
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline', 'strikethrough'],
    bold: { icon: ic_text_bold },
    italic: { icon: ic_text_italic },
    underline: { icon: ic_text_underline },
    strikethrough: { icon: ic_text_strikethrough },
  },
  textAlign: {
    inDropdown: false,
    options: ['left', 'center', 'right', 'justify'],
    left: { icon: ic_text_align_left },
    center: { icon: ic_text_align_center },
    right: { icon: ic_text_align_right },
    justify: { icon: ic_text_align_justify },
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered'],
    unordered: { icon: dot_list_icon },
    ordered: { icon: number_list_icon },
  },
  history: {
    inDropdown: false,
    options: ['undo', 'redo'],
    undo: { icon: undo_icon },
    redo: { icon: redo_icon },
  },
};

const RichTextEditor = ({ setFieldVal, fieldVal, newStyles, placeholder, maxLength, readOnly }) => {
  const [contentState, setContentState] = useState('');
  const [openToolbar, setOpenToolbar] = useState(true);
  const [initValInTheFistTime, setInitValInTheFistTime] = useState(true);
  const initData = fieldVal && JSON.stringify(fieldVal).includes('blocks') ? convertFromRaw(JSON.parse(fieldVal)) : ContentState.createFromText('');
  const [editorState, setEditorState] = useState(EditorState.createWithContent(initData));
  const [isBlur, setIsBlur] = useState(true);
  const [tempPlaceholder, setTempPlaceholder] = useState(placeholder);

  useEffect(() => {
    if (initValInTheFistTime && fieldVal && JSON.stringify(fieldVal).includes('blocks')) {
      setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(fieldVal))));
    }
  }, [fieldVal, initValInTheFistTime]);

  const isEditorEmpty = useMemo(() => {
    const tempContent = editorState.getCurrentContent();
    return !tempContent.hasText();
  }, [editorState]);

  const handleClear = useCallback(() => {
    setEditorState(EditorState.createEmpty());
    setTempPlaceholder(placeholder);
  }, [setEditorState]);

  useEffect(() => {
    if (isEditorEmpty && isBlur) {
      handleClear();
    }
  }, [isEditorEmpty, isBlur, handleClear]);

  const onEditorStateChange = (newState) => {
    const tempData = convertToRaw(newState.getCurrentContent());
    setTempPlaceholder('');
    setEditorState(newState);
    setContentState(JSON.stringify(tempData));
  };

  const handleBlur = () => {
    setFieldVal(contentState);
    setIsBlur(true);
  };

  const handleBeforeInput = (input) => {
    const currentContent = editorState.getCurrentContent();
    const currentLength = currentContent.getPlainText('').length;
    if (maxLength && currentLength + input.length > maxLength) {
      return 'handled';
    }
    return 'not-handled';
  };

  const handlePastedText = (pastedText) => {
    const currentContent = editorState.getCurrentContent();
    const currentLength = currentContent.getPlainText('').length;
    if (maxLength && currentLength + pastedText.length > maxLength) {
      return 'handled';
    }
    return 'not-handled';
  };

  return (
    <div className={styles.editorContainer}>
      {!readOnly && (
        <div className={styles.controlToolbar}>
          {openToolbar ? (
            <button type="button" className={styles.toolbarBtn} onClick={() => setOpenToolbar(false)}>
              <img src={text_editor_icon} alt="" />
            </button>
          ) : (
            <button type="button" className={styles.toolbarBtn} onClick={() => setOpenToolbar(true)}>
              <img src={text_editor_active_icon} alt="" />
            </button>
          )}
        </div>
      )}
      <Editor
        editorState={editorState}
        toolbarHidden={readOnly ? true : openToolbar}
        wrapperClassName={readOnly ? 'readOnly wrapperClassName' : 'wrapperClassName'}
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        toolbar={toolBarOptions}
        editorStyle={{
          ...newStyles,
          minHeight: newStyles?.minHeight || 'auto',
          maxHeight: newStyles?.maxHeight || 'auto',
          overflowY: 'auto',
        }}
        onBlur={handleBlur}
        onFocus={() => {
          setIsBlur(false);
          setInitValInTheFistTime(false);
        }}
        placeholder={tempPlaceholder || ''}
        handleBeforeInput={handleBeforeInput}
        handlePastedText={handlePastedText}
        readOnly={readOnly}
      />
    </div>
  );
};

export default RichTextEditor;
